import logo from './logo.svg';
import './App.css';
import { Component } from "react";

let vh_in_pixel = document.documentElement.clientHeight;
let vw_in_pixel = document.documentElement.clientWidth;

export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tic: 3000
    }
    this.countDown = this.countDown.bind(this);

  }

  componentDidMount() {
    let foo = this.countDown
    setInterval(function () {
      foo();
    }, 100)
  }

  countDown() {
    this.setState((prevState) => {
      let prevtic = prevState.tic
      let newtic = prevtic - 1
      if (prevtic === 0) {
        document.location.reload();
        newtic = 3000
      }
      return { tic: newtic }
    })
  }

  render() {
    const { tic } = this.state;
    return (
      <>
        <img className={"logo"} style={{ position: 'fixed', top: '0px', left: '5vw', maxWidth: '50vw', zIndex: '-5', opacity: .2 }} src={"https://dvic.devinci.fr/nextcloud/apps/files_sharing/publicpreview/xLCEnC6bKfcMDLa?file=/Logo%20DVIC.png&fileId=16799&x=1920&y=1080&a=true"} />
        <h1 style={{ fontSize: '3rem', fontWeight: 600 }}>DVIC QUICKSTARTERS 2023 - MONITORING</h1>
        <div className="App" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100vw' }}>
          <iframe src="https://www.kickstarter.com/projects/the-leather-cover/leather-cover/widget/card.html?v=2"
            width={`${vw_in_pixel * 0.138}`} height="500" frameBorder="0" scrolling="no"></iframe>

          <iframe src="https://www.kickstarter.com/projects/paddersquickstarter/solution-to-reduce-food-waste/widget/card.html?v=2"
            width={`${vw_in_pixel * 0.138}`} height="500" frameBorder="0" scrolling="no"></iframe>

          <iframe src="https://www.kickstarter.com/projects/taystick/quickstarter-taystick/widget/card.html?v=2"
            width={`${vw_in_pixel * 0.138}`} height="500" frameBorder="0" scrolling="no"></iframe>

          <iframe src="https://www.kickstarter.com/projects/violetteanicet/keyvoila-grab-your-keys/widget/card.html?v=2"
            width={`${vw_in_pixel * 0.138}`} height="500" frameBorder="0" scrolling="no"></iframe>

          <iframe src="https://www.kickstarter.com/projects/clairelefez/bee2win/widget/card.html?v=2"
            width={`${vw_in_pixel * 0.138}`} height="500" frameBorder="0" scrolling="no"></iframe>

          <iframe src="https://www.kickstarter.com/projects/the-inhome-garden/the-inhome-garden/widget/card.html?v=2"
            width={`${vw_in_pixel * 0.138}`} height="500" frameBorder="0" scrolling="no"></iframe>

          <iframe src="https://www.kickstarter.com/projects/stressbud/quickstarter-stressbud-a-fidget-toy-to-focus/widget/card.html?v=2"
            width={`${vw_in_pixel * 0.138}`} height="500" frameBorder="0" scrolling="no"></iframe>

        </div>
      </>
    );
  }
}

